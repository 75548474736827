<template>
  <div>
    <div class="login-form-container">
      <div class="login-register-form">
        <!-- <div class="successs" style="display: none"></div>
        <div class="error_register"></div> -->
        <!-- <br /> -->
        <!-- <div align="center" class="loader_register" style="display: none">
          <div class="spinner-border text-danger loader"></div>
        </div> -->

        <form @submit.prevent="registerUser">
          <input
            v-model="form.first_name"
            type="text"
            name="first_name"
            placeholder="Nombre(s)"
          />
          <input
            v-model="form.last_name"
            type="text"
            name="last_name"
            placeholder="Apellido(s)"
          />
          <!-- <input
            v-model="form.phone"
            type="text"
            name="phone"
            placeholder="Telefono"
          /> -->
          <VuePhoneNumberInput
            class="mb-8 pt-0"
            :preferred-countries="['MX']"
            :color="'#FAB848'"
            :default-country-code="'MX'"
            v-model="form.phone"
            :translations="{
              countrySelectorLabel: 'Código del país',
              countrySelectorError: 'Choisir un pays',
              phoneNumberLabel: 'Némero de teléfono',
              example: 'Ejemplo: ',
            }"
          />
          <input
            v-model="form.email"
            type="email"
            name="email"
            placeholder="Email"
          />
          <input
            v-model="form.password"
            type="password"
            name="password"
            placeholder="Contraseña"
          />
          <input
            v-model="form.password_confirmation"
            type="password"
            name="password_confirmation"
            placeholder="Confirmacion de Contraseña"
          />

          <v-alert
            v-for="(error, idx) in getErrors"
            :key="idx"
            dense
            text
            type="error"
          >
            {{ error }}
          </v-alert>
          <div class="button-box">
            <v-btn
              color="primary"
              block
              depressed
              dark
              large
              :loading="isLoading"
              type="submit"
            >
              Registrarse
            </v-btn>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import * as yup from "yup";
export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      isLoading: false,
    };
  },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Registro",
      };
    },
  },

  computed: {
    ...mapGetters("auth", ["getErrors"]),
    async validateForm() {
      this.setErrors("clear");
      let schemaUser = yup.object().shape({
        password_confirmation: yup
          .string()
          .required("La confirmación de contraseña es requerida")
          .oneOf([yup.ref("password")], "Las contraseñas no coinciden"),
        password: yup.string().required("La conntraseña es requerida"),
        email: yup
          .string()
          .email("El email debe ser válido")
          .required("El email es requerido"),
        phone: yup.string().required("El teléfono es requerido"),
        last_name: yup.string().required("El apellido es requerido"),
        first_name: yup.string().required("El nombre es requerido"),
      });

      try {
        await schemaUser.validate(this.form);
        return true;
      } catch (error) {
        this.setErrors(error.errors[0]);
        return false;
      }
    },
  },
  created() {
    this.setErrors("clear");
  },
  methods: {
    ...mapActions("auth", ["register", "setErrors"]),

    async registerUser() {
      console.log("register");
      if (await this.validateForm) {
        this.isLoading = true;
        const user = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          phone: this.form.phone,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        };
        await this.register(user);
        if (this.getErrors.length == 0) {
          this.isLoading = false;
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
input:focus {
  border-color: #FAB848 !important;
}
</style>
